import { HStack, IconButton } from "@chakra-ui/react";
import { FC, useState } from "react";
import {
  AiFillAccountBook,
  AiFillCalendar,
  AiFillHome,
  AiFillOpenAI,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LayoutSliceSelector, setActiveMenu } from "state/layout/layoutState";
import { TabBar } from "types/layout";

const AppTabBar: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const layoutSlice = useSelector(LayoutSliceSelector);
  const activeTabBar = layoutSlice.activeTabBar;

  const changeActiveTab = (tab: TabBar) => {
    dispatch(setActiveMenu(tab));
    switch (tab) {
      case "Home":
        navigate("/shop");
        break;
      case "Booking":
        navigate("/booking");
        break;
      case "Reports":
        navigate("/reports");
        break;
      case "Ai":
        navigate("/ai");
        break;
      default:
    }
  };

  return (
    <HStack w="full" justify="space-between" bg="#fff" p={3} borderRadius="8px">
      <IconButton
        size={{
          base: "md",
          md: "lg",
        }}
        colorScheme="orange"
        aria-label="home"
        isActive={activeTabBar === "Home"}
        icon={<AiFillHome />}
        onClick={() => changeActiveTab("Home")}
      />
      <IconButton
        size={{
          base: "md",
          md: "lg",
        }}
        colorScheme="orange"
        aria-label="Booking"
        isActive={activeTabBar === "Booking"}
        onClick={() => changeActiveTab("Booking")}
        icon={<AiFillCalendar />}
      />
      <IconButton
        size={{
          base: "md",
          md: "lg",
        }}
        colorScheme="orange"
        aria-label="Reports"
        isActive={activeTabBar === "Reports"}
        onClick={() => changeActiveTab("Reports")}
        icon={<AiFillAccountBook />}
      />
      <IconButton
        size={{
          base: "md",
          md: "lg",
        }}
        colorScheme="orange"
        aria-label="Ai"
        isActive={activeTabBar === "Ai"}
        onClick={() => changeActiveTab("Ai")}
        icon={<AiFillOpenAI />}
      />
    </HStack>
  );
};

export default AppTabBar;
