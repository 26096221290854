import { HStack, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

const AppEmptyLayout = () => {
  return (
    <HStack
      h="100vh"
      w="full"
      bg="#ffe1e1"
      p={{
        base: 4,
        md: 5,
        lg: 10,
      }}
    >
      <VStack w="full" h="full" justify="center">
        <Outlet />
      </VStack>
    </HStack>
  );
};

export default AppEmptyLayout;
