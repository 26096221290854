import { apiClient } from "api/axiosInstances";
import { ApiResponse } from "types/api";
import { GetBookerShopUrlParamsI } from "types/requests/booker";

export const getShopListRequest = async (
    urlParams: GetBookerShopUrlParamsI
): Promise<ApiResponse<any[]>> => {
    try {
        const response = await apiClient.get('/booker/shop', { params: urlParams });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// export const getShopJobsRequest = async (
//     urlParams: GetBookerShopUrlParamsI
// ): Promise<ApiResponse<any[]>> => {
//     try {
//         const response = await apiClient.get('/bookings', { params: urlParams });
//         return response.data;
//     } catch (error) {
//         console.error(error);
//         throw error;
//     }
// };