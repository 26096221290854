import { BoxProps, Center, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";

interface Props extends BoxProps {
  item: number;
}

const TimeItem: FC<Props> = ({ item, ...rest }) => {
  return (
    <Center
      w={10}
      h={10}
      bg="#000"
      borderRadius={5}
      cursor="pointer"
      _active={{ bg: "#555" }}
      {...rest}
    >
      <Text color="#fff">{item}</Text>
    </Center>
  );
};

export default TimeItem;
