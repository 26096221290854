import { Spinner } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getShopDashboardRequest,
  ShopSliceSelector,
} from "state/shop/shopState";
import PropList from "./PropList/PropList";

const ShopDashboard: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shopSelector = useSelector(ShopSliceSelector);
  const selectedShop = shopSelector.selectedShop;
  const propList = shopSelector.propList;

  const getShopDashboardProperties = () => {
    selectedShop &&
      dispatch(
        getShopDashboardRequest({
          shopId: selectedShop.shopId,
        })
      );
  };

  useEffect(() => {
    if (!selectedShop) {
      navigate("/");
    }

    getShopDashboardProperties();
  }, [shopSelector.watchRange, selectedShop]);

  return shopSelector.loadings.shopDashboard && propList.length === 0 ? (
    <Spinner />
  ) : (
    <PropList props={propList} />
  );
};

export default ShopDashboard;
