import { HStack, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import AppHeader from "./AppHeader";
import AppTabBar from "./AppTabBar";

const AppMainLayout = () => {
  return (
    <HStack
      h="100vh"
      w="full"
      bg="#ffe1e1"
      p={{
        base: 2,
        md: 5,
        lg: 10,
      }}
    >
      <VStack w="full" h="full" justify="space-between">
        <AppHeader />
        <HStack bg="#fff" h="full" w="full" justify="center" borderRadius="8px">
          <Outlet />
        </HStack>
        <AppTabBar />
      </VStack>
    </HStack>
  );
};

export default AppMainLayout;
