import { apiClient } from 'api/axiosInstances';
import { ApiResponse } from 'types/api';
import { OwnerShopListRequestQueryParamsI, PropertyBookingCount, PropertyBookingListRequestBodyParamsI, ShopDashboardRequestBodyParamsI } from 'types/requests/shop';
import { OwnerShopListResponseI } from 'types/responses/shop';

export const getOwnerShopListRequest = async (
  queryParams: OwnerShopListRequestQueryParamsI
): Promise<ApiResponse<OwnerShopListResponseI>> => {
  try {
    const response = await apiClient.get('/shops', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getShopDashboardRequest = async (
  bodyParams: ShopDashboardRequestBodyParamsI | PropertyBookingListRequestBodyParamsI
): Promise<ApiResponse<PropertyBookingCount[]>> => {
  try {
    const response = await apiClient.post('/shopDashboard', bodyParams);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
