import { call, put, select, takeEvery } from 'redux-saga/effects';
import { bookingApi, shopApi } from 'api';
import { ApiResponse } from 'types/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { showAxiosErrorMessage, showSuccessToast } from 'state/toast/toastState';
import { PropertyBookingListRequestBodyParamsI } from 'types/requests/shop';
import { createBookingRequestFailure, createBookingRequestSuccess, getPropertyBookingListRequestFailure, getPropertyBookingListRequestSuccess } from './propertyState';
import { IShopState, ShopSliceSelector } from 'state/shop/shopState';
import { GetBookingListResponseI } from 'types/responses/shop';
import { CreateBookingRequestBodyParamsI } from 'types/requests/booking';


function* getPropertyBookingListRequestSaga(action: PayloadAction<PropertyBookingListRequestBodyParamsI>) {
    try {
        const bodyParams = action.payload;
        const shopSelector: IShopState = yield select(ShopSliceSelector);

        bodyParams.shopId = shopSelector.selectedShop?.shopId!;
        bodyParams.startDate = shopSelector.watchRange.startDate;
        bodyParams.endDate = shopSelector.watchRange.endDate;

        const shopDashboardResponse: ApiResponse<GetBookingListResponseI> = yield call(() =>
            shopApi.getShopDashboardRequest(bodyParams)
        );
        yield put(getPropertyBookingListRequestSuccess(shopDashboardResponse.body));
    } catch (error: any) {
        yield put(getPropertyBookingListRequestFailure());
        showAxiosErrorMessage(error);
    }
}

function* createBookingRequestSaga(action: PayloadAction<CreateBookingRequestBodyParamsI>) {
    try {
        const bodyParams = action.payload;

        const createBookingResponse: ApiResponse<GetBookingListResponseI> = yield call(() =>
            bookingApi.createBookingRequest(bodyParams)
        );

        if (createBookingResponse.statusCode === 200) {
            showSuccessToast('Booking created successfully');
        }
        yield put(createBookingRequestSuccess());
    } catch (error: any) {
        yield put(createBookingRequestFailure());
        showAxiosErrorMessage(error);
    }
}

function* PropertySaga() {
    yield takeEvery('property/getPropertyBookingListRequest', getPropertyBookingListRequestSaga);
    yield takeEvery('property/createBookingRequest', createBookingRequestSaga);
}

export default PropertySaga;
