import { HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertyBookingListRequest,
  PropertySliceSelector,
} from "state/property/propertyState";
import { ShopSliceSelector } from "state/shop/shopState";
import BookingList from "./BookingList/BookingList";

const PropertyScreen = () => {
  const shopSelector = useSelector(ShopSliceSelector);
  const propertySelector = useSelector(PropertySliceSelector);
  const propId = propertySelector.selectedProperty;
  const bookingList = propertySelector.bookingList;
  const dispatch = useDispatch();

  useEffect(() => {
    getPropertyBookingList();
  }, [shopSelector.watchRange]);

  const getPropertyBookingList = async () => {
    propId && dispatch(getPropertyBookingListRequest({ propId }));
  };

  return (
    <VStack h="full" align="center" w="full">
      <HStack px={3} py={2}>
        <Text fontSize={20} fontWeight={600}>
          {propId} - Bookings
        </Text>
      </HStack>
      {propertySelector.loadings.bookingList ? (
        <Spinner />
      ) : (
        <BookingList bookingList={bookingList} />
      )}
    </VStack>
  );
};

export default PropertyScreen;
