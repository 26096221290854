import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRootState } from 'state/rootState';
import { CreateBookingRequestBodyParamsI } from 'types/requests/booking';
import { PropertyBookingListRequestBodyParamsI } from 'types/requests/shop';
import { BookingI, GetBookingListResponseI } from 'types/responses/shop';

export interface IPropertyState {
    bookingList: BookingI[] | [];
    selectedProperty: string | null;
    loadings: {
        bookingList: boolean;
        createBooking: boolean;
    };
}

export const ShopSlice = createSlice({
    name: 'property',
    initialState: {
        bookingList: [],
        selectedProperty: null,
        loadings: {
            bookingList: false,
            createBooking: false,
        }
    } as IPropertyState,
    reducers: {
        getPropertyBookingListRequest: (state, action: PayloadAction<PropertyBookingListRequestBodyParamsI>) => {
            state.loadings.bookingList = true;
        },
        getPropertyBookingListRequestSuccess: (state, action: PayloadAction<GetBookingListResponseI>) => {
            state.bookingList = action.payload.bookings;
            state.loadings.bookingList = false;
        },
        getPropertyBookingListRequestFailure: (state) => {
            state.loadings.bookingList = false;
        },
        setSelectedProperty: (state, action: PayloadAction<string | null>) => {
            state.selectedProperty = action.payload;
        },
        createBookingRequest: (state, action: PayloadAction<CreateBookingRequestBodyParamsI>) => {
            state.loadings.createBooking = true
        },
        createBookingRequestSuccess: (state) => {
            state.loadings.createBooking = false
        },
        createBookingRequestFailure: (state) => {
            state.loadings.createBooking = false
        }
    }
});

export const PropertySliceSelector = (state: IRootState) => state.property;

export const {
    getPropertyBookingListRequest,
    getPropertyBookingListRequestSuccess,
    getPropertyBookingListRequestFailure,
    setSelectedProperty,
    createBookingRequest,
    createBookingRequestSuccess,
    createBookingRequestFailure
} = ShopSlice.actions;

export default ShopSlice.reducer;
