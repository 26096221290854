import { createBrowserRouter } from "react-router-dom";
import AppEmptyLayout from "layout/AppEmptyLayout";
import BookerLanding from "screens/booker/BookerLanding";
import ShopSearch from "screens/booker/ShopSearch";
import CreateBooking from "screens/booker/CreateBooking/CreateBooking";

const bookerRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppEmptyLayout />,
    children: [
      {
        path: "/",
        element: <BookerLanding />,
      },
      {
        path: "/shop-search",
        element: <ShopSearch />,
      },
      {
        path: "/create-booking",
        element: <CreateBooking />,
      },
    ],
  },
]);

export default bookerRouter;
