import { apiClient } from "api/axiosInstances";
import { ApiResponse } from "types/api";
import { CreateBookingRequestBodyParamsI, GetShopListUrlParamsI } from "types/requests/booking";

export const createBookingRequest = async (
    bodyParams: CreateBookingRequestBodyParamsI
): Promise<ApiResponse<any[]>> => {
    try {
        const response = await apiClient.post('/bookings', bodyParams);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getShopListRequest = async (
    urlParams: GetShopListUrlParamsI
): Promise<ApiResponse<any[]>> => {
    try {
        const response = await apiClient.get('/bookings', { params: urlParams });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};