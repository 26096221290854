import { PayloadAction } from "@reduxjs/toolkit";
import { bookerApi } from "api";
import { call, put, takeEvery } from "redux-saga/effects";
import { showAxiosErrorMessage } from "state/toast/toastState";
import { ApiResponse } from "types/api";
import { ShopI } from "types/responses/shop";
import { getBookerShopListRequestFailure, getBookerShopListRequestSuccess } from "./bookerState";
import { GetBookerShopUrlParamsI } from "types/requests/booker";

function* getBookerShopListRequestSaga(action: PayloadAction<GetBookerShopUrlParamsI>) {
    try {
        const queryParams = action.payload;
        const bookerShopListResponse: ApiResponse<ShopI[]> = yield call(() =>
            bookerApi.getShopListRequest(queryParams)
        );
        yield put(getBookerShopListRequestSuccess(bookerShopListResponse.body));
    } catch (error: any) {
        yield put(getBookerShopListRequestFailure());
        showAxiosErrorMessage(error);
    }
}

function* RetailerSaga() {
    yield takeEvery('booker/getBookerShopListRequest', getBookerShopListRequestSaga);
}

export default RetailerSaga;