import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "state/store";
import { ToastContainer, ToastContainerProps } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Authenticator } from "@aws-amplify/ui-react";
import { Center, ChakraProvider } from "@chakra-ui/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const toastContainerConfig: ToastContainerProps = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  theme: "dark",
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider>
        <Center w="100vw" h="100vh" border="1px solid #acacff">
          <Authenticator initialState="signIn" signUpAttributes={["email"]}>
            <App />
          </Authenticator>
        </Center>
      </ChakraProvider>
      <ToastContainer {...toastContainerConfig} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorkerRegistration.register();
