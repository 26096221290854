import { Button, HStack, Input, VStack } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BookerSliceSelector,
  getBookerShopListRequest,
  setSelectedShop,
} from "state/booker/bookerState";
import { ShopI } from "types/responses/shop";

const ShopSearch: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookerSelector = useSelector(BookerSliceSelector);
  const shopList = bookerSelector.shopList;

  useEffect(() => {
    fetchShopList();
  }, []);

  const onShopSelect = (shop: ShopI) => {
    dispatch(setSelectedShop(shop));
    navigate("/create-booking");
  };

  const fetchShopList = () => {
    console.log("Fetching Shop List");
    dispatch(getBookerShopListRequest({ category: "salon", city: "kandy" }));
  };

  return (
    <VStack h="full" w="full">
      <HStack w="full" justify="flex-end">
        <Input
          placeholder="Search"
          colorScheme="red"
          border="1px solid #ffffff !important"
        />
        <Button onClick={() => {}}>Go</Button>
      </HStack>
      <VStack>
        {shopList &&
          shopList.map((shop) => (
            <Button onClick={() => onShopSelect(shop)}>{shop.shopId}</Button>
          ))}
      </VStack>
    </VStack>
  );
};

export default ShopSearch;
