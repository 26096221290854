import { combineReducers } from '@reduxjs/toolkit';
import shopReducer, { IShopState } from './shop/shopState';
import userReducer, { IUserState } from './user/userState';
import propertyReducer, { IPropertyState } from './property/propertyState';
import bookerReducer, { IBookerState } from './booker/bookerState';
import layoutReducer, { ILayoutState } from './layout/layoutState';
import notificationReducer, { INotificationState } from './notification/notificationState';

export interface IRootState {
  shop: IShopState;
  user: IUserState;
  property: IPropertyState;
  layout: ILayoutState;
  booker: IBookerState;
  notification: INotificationState;
}

export const rootReducer = combineReducers({
  shop: shopReducer,
  user: userReducer,
  property: propertyReducer,
  layout: layoutReducer,
  booker: bookerReducer,
  notification: notificationReducer,
});
