import React, { useRef, useState } from "react";
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode";
import { showErrorToast, showSuccessToast } from "state/toast/toastState";
import { Button, VStack } from "@chakra-ui/react";
import { BsQrCode } from "react-icons/bs";

interface Props {
  onScanComplete: (data: string) => void;
}

const QrCodeScanner: React.FC<Props> = ({ onScanComplete }) => {
  const qrCodeRegionRef = useRef<HTMLDivElement | null>(null);
  const [isScannerInitialized, setIsScannerInitialized] = useState(false);

  // Function to start the scanner
  const startScan = () => {
    if (qrCodeRegionRef.current && !isScannerInitialized) {
      // Initialize the QR code scanner on button click
      const html5QrCodeScanner = new Html5QrcodeScanner(
        qrCodeRegionRef.current.id,
        {
          fps: 10, // Frames per second for scanning
          qrbox: { width: 250, height: 250 }, // Scanning box size
          supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA], // Use camera for scanning
        },
        false // Disable verbose logging
      );

      // Start scanning and handle the success/error cases
      html5QrCodeScanner.render(onScanSuccess, onScanError);

      setIsScannerInitialized(true); // Prevent re-initializing the scanner
    }
  };

  // Function to handle successful scan
  const onScanSuccess = (decodedText: string, decodedResult: any) => {
    showSuccessToast("QR Code Scanned");
    onScanComplete(decodedText);
  };

  // Function to handle errors during scanning
  const onScanError = (errorMessage: string) => {
    // showErrorToast(`QR Code scan error: ${errorMessage}`);
  };

  return (
    <VStack>
      <Button
        onClick={startScan}
        bg="#000"
        color="#fff"
        rightIcon={<BsQrCode />}
      >
        QR Scan
      </Button>

      <div
        id="qr-reader"
        ref={qrCodeRegionRef}
        style={{
          width: "100%",
          display: isScannerInitialized ? "block" : "none",
        }}
        // onBlur={() => setIsScannerInitialized(false)}
      />
    </VStack>
  );
};

export default QrCodeScanner;
