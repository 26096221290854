import { HStack, IconButton, Tag, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import DateFilters from "components/DateFilters/DateFilters";
import { headerDisplaySelectedWatchRange } from "utils/date";
import { useSelector } from "react-redux";
import { ShopSliceSelector } from "state/shop/shopState";

const AppHeader: FC = () => {
  const shopSelector = useSelector(ShopSliceSelector);
  const watchRange = shopSelector.watchRange;
  const navigate = useNavigate();

  const onClickBackButton = () => {
    navigate(-1);
  };

  return (
    <HStack w="full" justify="space-between">
      <HStack>
        <IconButton
          size={{
            base: "xs",
            md: "sm",
            lg: "md",
          }}
          colorScheme="gray"
          aria-label="back button"
          icon={<AiOutlineLeft />}
          onClick={onClickBackButton}
        />

        <VStack align="start" gap="2px">
          <Text color="#ff6500" fontSize="14px" fontWeight={600}>
            {shopSelector.selectedShop?.shopId}
          </Text>
          <HStack>
            <Tag size="sm" bg="black">
              <Text color="#fff" fontSize="10px">
                {headerDisplaySelectedWatchRange(watchRange)}
              </Text>
            </Tag>
          </HStack>
        </VStack>
      </HStack>
      <DateFilters />
    </HStack>
  );
};

export default AppHeader;
