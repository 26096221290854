import { createBrowserRouter } from "react-router-dom";
import AppMainLayout from "layout/AppMainLayout";
import LandingView from "screens/owner/shop/LandingView";
import AppEmptyLayout from "layout/AppEmptyLayout";
import ShopDashboard from "screens/owner/shop/ShopDashboard";
import PropertyScreen from "screens/owner/property/PropertyScreen";
import BookingScreen from "screens/owner/booking/BookingScreen";
import AppFooterOnlyLayout from "layout/AppFooterOnlyLayout";

const ownerRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppEmptyLayout />,
    children: [
      {
        path: "/",
        element: <LandingView />,
      },
    ],
  },
  {
    path: "/shop",
    element: <AppMainLayout />,
    children: [
      {
        path: "/shop",
        element: <ShopDashboard />,
      },
      {
        path: "/shop/property",
        element: <PropertyScreen />,
      },
    ],
  },
  {
    path: "/booking",
    element: <AppFooterOnlyLayout />,
    children: [
      {
        path: "/booking",
        element: <BookingScreen />,
      },
    ],
  },
  {
    path: "/reports",
    element: <AppFooterOnlyLayout />,
    children: [
      {
        path: "/reports",
        element: <h1>Reports</h1>,
      },
    ],
  },
  {
    path: "/Ai",
    element: <AppFooterOnlyLayout />,
    children: [
      {
        path: "/Ai",
        element: <h1>Ai Support</h1>,
      },
    ],
  },
]);

export default ownerRouter;
