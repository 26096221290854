import "./App.css";
import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";
import "@aws-amplify/ui-react/styles.css";
import { Button, Spinner, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import awsConfig from "./aws-exports";
import { jwtDecode } from "jwt-decode";
import { RouterProvider } from "react-router-dom";
import ownerRouter from "routes/ownerRouter";
import { useDispatch, useSelector } from "react-redux";
import { setCurrantUser, UserSliceSelector } from "state/user/userState";
import { User } from "types/user";
import bookerRouter from "routes/bookerRouter";
import {
  onMessageListener,
  requestFirebaseNotificationPermission,
} from "firebaseConfig";
import { updateDeviceTokenRequest } from "state/notification/notificationState";
import { showSuccessToast } from "state/toast/toastState";

Amplify.configure(awsConfig);

const App: FC = ({}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const userSliceSelector = useSelector(UserSliceSelector);
  const currantUser = userSliceSelector.currantUser;
  const userRole = currantUser?.["custom:role"];
  const [permission, setPermission] = useState(Notification.permission);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userRole) {
      requestFirebaseNotificationPermission().then((token) => {
        if (token) {
          dispatch(
            updateDeviceTokenRequest({
              deviceToken: token,
              userName: currantUser?.["cognito:username"]!,
              userPoolId: awsConfig.Auth.Cognito.userPoolId,
            })
          );
        }
      });

      onMessageListener().then((payload) => {
        // Customize notification display here
        showSuccessToast("New Notification Received");
      });
    }

    fetchToken();
  }, [userRole]);

  const fetchToken = async () => {
    try {
      setIsLoading(true);
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString()!;
      const decodedToken: User = jwtDecode(authToken);
      dispatch(setCurrantUser(decodedToken));
      localStorage.setItem("authToken", authToken!);
      setIsLoading(false);
    } catch (error) {
      console.error("Error getting ID token:", error);
    }
  };

  const requestNotificationPermission = () => {
    if ("Notification" in window && navigator.serviceWorker) {
      Notification.requestPermission().then((permission) => {
        setPermission(permission);
        if (permission === "granted") {
          console.log("Notification permission granted.");
        }
      });
    }
  };

  return (
    <>
      {permission !== "granted" && (
        <Button onClick={requestNotificationPermission}>
          Enable Notifications
        </Button>
      )}
      {!isLoading ? (
        <>
          {userRole === "Owner" && (
            <RouterProvider
              router={ownerRouter}
              fallbackElement={<Spinner />}
            />
          )}
          {userRole === "Booker" && (
            <RouterProvider
              router={bookerRouter}
              fallbackElement={<Spinner />}
            />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default App;
