import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRootState } from 'state/rootState';
import { TabBar } from 'types/layout';

export interface ILayoutState {
    activeTabBar: TabBar;
}

export const LayoutSlice = createSlice({
    name: 'layout',
    initialState: {
        activeTabBar: "Home",
    } as ILayoutState,
    reducers: {
        setActiveMenu: (state, action: PayloadAction<TabBar>) => {
            state.activeTabBar = action.payload;
        }
    }
});

export const LayoutSliceSelector = (state: IRootState) => state.layout;

export const {
    setActiveMenu,
} = LayoutSlice.actions;

export default LayoutSlice.reducer;
