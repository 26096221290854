import { call, put, select, takeEvery } from 'redux-saga/effects';
import { shopApi } from 'api';
import { ApiResponse } from 'types/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { showAxiosErrorMessage, showSuccessToast } from 'state/toast/toastState';
import { OwnerShopListRequestQueryParamsI, PropertyBookingCount, ShopDashboardRequestBodyParamsI } from 'types/requests/shop';
import { OwnerShopListResponseI } from 'types/responses/shop';
import { getOwnerShopListRequestFailure, getOwnerShopListRequestSuccess, getShopDashboardRequestFailure, getShopDashboardRequestSuccess, IShopState, ShopSliceSelector } from './shopState';

function* getOwnerShopListRequestSaga(action: PayloadAction<OwnerShopListRequestQueryParamsI>) {
    try {
        const queryParams = action.payload;
        const ownerListResponse: ApiResponse<OwnerShopListResponseI> = yield call(() =>
            shopApi.getOwnerShopListRequest(queryParams)
        );
        yield put(getOwnerShopListRequestSuccess(ownerListResponse.body));
    } catch (error: any) {
        yield put(getOwnerShopListRequestFailure());
        showAxiosErrorMessage(error);
    }
}

function* getShopDashboardRequestSaga(action: PayloadAction<ShopDashboardRequestBodyParamsI>) {
    try {
        const bodyParams = action.payload;
        const shopSelector: IShopState = yield select(ShopSliceSelector);

        bodyParams.startDate = shopSelector.watchRange.startDate;
        bodyParams.endDate = shopSelector.watchRange.endDate

        const shopDashboardResponse: ApiResponse<PropertyBookingCount[]> = yield call(() =>
            shopApi.getShopDashboardRequest(bodyParams)
        );
        yield put(getShopDashboardRequestSuccess(shopDashboardResponse.body));
    } catch (error: any) {
        yield put(getShopDashboardRequestFailure());
        showAxiosErrorMessage(error);
    }
}

function* RetailerSaga() {
    yield takeEvery('shop/getOwnerShopListRequest', getOwnerShopListRequestSaga);
    yield takeEvery('shop/getShopDashboardRequest', getShopDashboardRequestSaga);
}

export default RetailerSaga;
