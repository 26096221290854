import { Text, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { BookerSliceSelector } from "state/booker/bookerState";

const CreateBooking: FC = () => {
  const bookerSelector = useSelector(BookerSliceSelector);
  const selectedShop = bookerSelector.selectedShop;

  return (
    <VStack>
      {selectedShop &&
        selectedShop.jobs.map((job, key) => {
          const jobName = Object.keys(job)[0];
          const jobDetails = job[jobName];

          return (
            <Text>
              {jobName} - price {jobDetails.price} - time {jobDetails.time}{" "}
            </Text>
          );
        })}
    </VStack>
  );
};

export default CreateBooking;
