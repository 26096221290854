import { HStack, Tag, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { BookingI } from "types/responses/shop";
import { getTheTimeFromDateTime } from "utils/date";

interface Props {
  booking: BookingI;
}

const BookingSlot: FC<Props> = ({ booking }) => {
  return (
    <VStack w="full" bg="black" borderRadius={8} p={2}>
      <HStack>
        <Tag colorScheme="teal">
          Start: {getTheTimeFromDateTime(booking.bookingStart)}
        </Tag>
        <Tag colorScheme="teal">
          End: {getTheTimeFromDateTime(booking.bookingEnd)}
        </Tag>
      </HStack>
      <HStack>
        <Text color="white">
          <Tag colorScheme="blue">Hair Cut</Tag>
        </Text>
      </HStack>
    </VStack>
  );
};

export default BookingSlot;
