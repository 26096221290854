import { FC } from "react";
import { Grid } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PropertyBookingCount } from "types/requests/shop";
import PropItem from "./PropItem";
import { setSelectedProperty } from "state/property/propertyState";

interface Props {
  props: PropertyBookingCount[];
}

const PropList: FC<Props> = ({ props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToProp = (property: string) => {
    dispatch(setSelectedProperty(property));
    navigate("/shop/property");
  };

  return (
    <Grid
      templateColumns={{
        base: "repeat(1, 1fr)",
        md: "repeat(3, 1fr)",
        lg: "repeat(4, 1fr)",
      }}
      rowGap={{
        base: 1,
        md: 2,
        lg: 5,
      }}
      columnGap={8}
      p={1}
    >
      {props &&
        props.length > 0 &&
        props.map((prop) => (
          <PropItem key={prop.propId} prop={prop} goToProp={goToProp} />
        ))}
    </Grid>
  );
};

export default PropList;
