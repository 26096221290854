import { AxiosInstance } from 'axios';

export const addTokenInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    const authToken = localStorage.getItem("authToken")!;
    if (authToken) {
      config.headers['Token'] = authToken;
    }
    return config;
  });
};

export const decodeResponseBody = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      response.data.body = JSON.parse(response.data.body);
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {

      }
      return Promise.reject(error);
    }
  );
};