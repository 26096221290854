import { Button, GridItem, HStack, Tag, Text } from "@chakra-ui/react";
import { FC } from "react";
import { PropertyBookingCount } from "types/requests/shop";

interface Props {
  prop: PropertyBookingCount;
  goToProp: (shop: string) => void;
}

const PropItem: FC<Props> = ({ prop, goToProp }) => {
  return (
    <GridItem key={prop.propId} w="full">
      <Button
        colorScheme="orange"
        color="white"
        size={{
          base: "sm",
        }}
        w="200px"
        onClick={() => goToProp(prop.propId)}
        h={{ base: "35px", md: "100px" }}
      >
        <HStack justify="space-between" w="full">
          <Text>{prop.propId}</Text> -{" "}
          <Tag color="#fff" bg="#000" borderRadius={20}>
            {prop.bookingCount}
          </Tag>
        </HStack>
      </Button>
    </GridItem>
  );
};

export default PropItem;
