import { GridItem, HStack, IconButton, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { BookingI } from "types/responses/shop";
import {
  AiFillCheckSquare,
  AiFillCloseSquare,
  AiFillPhone,
} from "react-icons/ai";
import BookingSlot from "./BookingSlot";

interface Props {
  booking: BookingI;
}

const BookingItem: FC<Props> = ({ booking }) => {
  return (
    <GridItem w="full">
      <VStack border="1px solid black" p={2} align="start">
        <BookingSlot booking={booking} />
        <HStack>
          <Text fontWeight="bold">Customer Name:</Text>
          <Text>Harshan</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Customer:</Text>
          <Link
            //  @ts-ignore *
            as="a"
            href="tel:+1234567890"
            color="teal.500"
            fontWeight="bold"
          >
            +94 (777) 268-684
          </Link>
          <AiFillPhone />
        </HStack>
        <HStack w="full" justify="space-between">
          <IconButton
            size={{
              base: "xs",
              md: "sm",
              lg: "md",
            }}
            colorScheme="red"
            aria-label="Cancel button"
            icon={<AiFillCloseSquare />}
            onClick={() => {}}
          />
          <IconButton
            size={{
              base: "xs",
              md: "sm",
              lg: "md",
            }}
            colorScheme="green"
            aria-label="Complete button"
            icon={<AiFillCheckSquare />}
            onClick={() => {}}
          />
        </HStack>
      </VStack>
    </GridItem>
  );
};

export default BookingItem;
