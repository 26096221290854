import { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Grid, GridItem, HStack, Text, VStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  createBookingRequest,
  PropertySliceSelector,
  setSelectedProperty,
} from "state/property/propertyState";
import TimePicker from "components/TimePicker/TimePicker";
import { Button, useDisclosure } from "@chakra-ui/react";
import { TimeType } from "types/booking";
import { setHours, setMinutes, setSeconds, setMilliseconds } from "date-fns";
import { ShopSliceSelector } from "state/shop/shopState";
import { UserSliceSelector } from "state/user/userState";
import { validateBookingTimes } from "utils/date";
import { showErrorToast } from "state/toast/toastState";

const BookingScreen = () => {
  const propertySelector = useSelector(PropertySliceSelector);
  const shopSelector = useSelector(ShopSliceSelector);
  const userSliceSelector = useSelector(UserSliceSelector);
  const currantUser = userSliceSelector.currantUser;
  const [bookingDate, setBookingDate] = useState<Date | null>(new Date());
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [activeTimeType, setActiveTimeType] = useState<TimeType>("START_TIME");
  const datePickerRef = useRef<any>(null);
  const selectedProperty = propertySelector.selectedProperty;
  const selectedShop = shopSelector.selectedShop;

  const dispatch = useDispatch();

  const {
    isOpen: isOpenTimePicker,
    onOpen: onOpenTimePicker,
    onClose: onCloseTimePicker,
  } = useDisclosure();

  function createDateTime(date: Date, timeStr: string) {
    const [hours, minutes] = timeStr.split(":").map(Number); // Split the time string and convert to numbers
    let updatedDate = setMinutes(setHours(date, hours), minutes); // Set hours and minutes
    updatedDate = setSeconds(updatedDate, 0); // Reset seconds to 0
    updatedDate = setMilliseconds(updatedDate, 0); // Reset milliseconds to 0
    return updatedDate;
  }

  const createPropertyBooking = async () => {
    if (
      !bookingDate ||
      !startTime ||
      !endTime ||
      !selectedProperty ||
      !selectedShop ||
      !currantUser
    ) {
      showErrorToast("Please fill in all fields.");
      return;
    }

    const sTime = createDateTime(bookingDate, startTime);
    const eTime = createDateTime(bookingDate, endTime);

    const isBookingTimeValid = validateBookingTimes(sTime, eTime);

    if (!isBookingTimeValid.valid) {
      showErrorToast(isBookingTimeValid.message);
      return;
    }

    const utcStartTime = sTime.toISOString();
    const utcEndTime = eTime.toISOString();

    const bookingData = {
      shopId: selectedShop?.shopId,
      propId: selectedProperty,
      bookingStart: utcStartTime,
      bookingEnd: utcEndTime,
      userName: currantUser?.["cognito:username"],
    };

    dispatch(createBookingRequest(bookingData));
  };

  const onStartTimePicker = (tm: TimeType) => {
    setActiveTimeType(tm);
    onOpenTimePicker();
  };

  const onTimeSelectionComplete = (selectedTime: string) => {
    activeTimeType === "START_TIME"
      ? setStartTime(selectedTime)
      : setEndTime(selectedTime);
    onCloseTimePicker();
  };

  const onSelectProperty = (prop: string | null) => {
    dispatch(setSelectedProperty(prop));
  };

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const handleFocus = (e: any) => {
    e.target.blur(); // Remove focus from the input
  };

  return (
    <>
      {isOpenTimePicker ? (
        <TimePicker onTimeSelectionComplete={onTimeSelectionComplete} />
      ) : (
        <VStack h="full" align="center" w="full" userSelect="none">
          <Text fontSize={20} fontWeight={600}>
            {selectedShop?.shopId}
          </Text>
          <HStack>
            <DatePicker
              selected={bookingDate}
              onChange={(date) => setBookingDate(date)}
              dateFormat="yyyy-MM-dd"
              ref={datePickerRef}
              minDate={new Date()}
              withPortal
              className="hidden-datepicker"
            />
            <Button onClick={openDatePicker}>Open Date Picker</Button>
          </HStack>
          <HStack>
            <Button onClick={() => onStartTimePicker("START_TIME")}>
              Start Time
            </Button>
            <Text>{startTime}</Text>
          </HStack>

          <HStack>
            <Button onClick={() => onStartTimePicker("END_TIME")}>
              End Time
            </Button>
            <Text>{endTime}</Text>
          </HStack>

          {!selectedProperty && (
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(3, 1fr)",
                lg: "repeat(4, 1fr)",
              }}
              rowGap={{
                base: 1,
                md: 2,
                lg: 5,
              }}
              columnGap={8}
              p={1}
            >
              {selectedShop &&
                selectedShop.props.length > 0 &&
                selectedShop.props.map((prop) => (
                  <GridItem key={prop}>
                    <Button
                      colorScheme="orange"
                      color="white"
                      size={{
                        base: "lg",
                      }}
                      w="100%"
                      onClick={() => onSelectProperty(prop)}
                    >
                      {prop}
                    </Button>
                  </GridItem>
                ))}
            </Grid>
          )}

          <Text onClick={() => onSelectProperty(null)}>Change property</Text>

          {selectedProperty && (
            <Button
              colorScheme="orange"
              color="white"
              size={{
                base: "lg",
              }}
              w="100%"
              disabled={true}
            >
              {selectedProperty}
            </Button>
          )}

          <Button onClick={createPropertyBooking} bg="#000" color="#fff">
            Confirm Booking
          </Button>
        </VStack>
      )}
    </>
  );
};

export default BookingScreen;
