import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRootState } from 'state/rootState';
import { GetBookerShopUrlParamsI } from 'types/requests/booker';
import { WatchRange } from 'types/requests/shop';
import { ShopI } from 'types/responses/shop';
import { getWatchRangeFromWatchOption } from 'utils/date';

export interface IBookerState {
    shopList: ShopI[] | [];
    selectedShop: ShopI | null;
    watchRange: WatchRange;
    loadings: {
        shopList: boolean;
    };
}

export const BookerSlice = createSlice({
    name: 'booker',
    initialState: {
        shopList: [],
        selectedShop: null,
        watchRange: getWatchRangeFromWatchOption('Today'),
        loadings: {
            shopList: false,
        }
    } as IBookerState,
    reducers: {
        getBookerShopListRequest: (state, action: PayloadAction<GetBookerShopUrlParamsI>) => {
            state.shopList = [];
            state.loadings.shopList = true;
        },
        getBookerShopListRequestSuccess: (state, action: PayloadAction<ShopI[]>) => {
            state.shopList = action.payload;
            state.loadings.shopList = false;
        },
        getBookerShopListRequestFailure: (state) => {
            state.shopList = [];
            state.loadings.shopList = false;
        },
        setSelectedShop: (state, action: PayloadAction<ShopI>) => {
            state.selectedShop = action.payload;
        }
    }
});

export const BookerSliceSelector = (state: IRootState) => state.booker;

export const {
    getBookerShopListRequest,
    getBookerShopListRequestSuccess,
    getBookerShopListRequestFailure,
    setSelectedShop,
} = BookerSlice.actions;

export default BookerSlice.reducer;
