import { PayloadAction } from "@reduxjs/toolkit";
import { notificationApi } from "api";
import { call, takeEvery } from "redux-saga/effects";
import { showAxiosErrorMessage } from "state/toast/toastState";
import { ApiResponse } from "types/api";
import { UpdateDeviceTokenBodyParamsI } from "types/requests/notification";

function* updateDeviceTokenRequestSaga(action: PayloadAction<UpdateDeviceTokenBodyParamsI>) {
    try {
        const bodyParams = action.payload;
        const bookerShopListResponse: ApiResponse<any> = yield call(() =>
            notificationApi.updateDeviceTokenRequest(bodyParams)
        );
    } catch (error: any) {
        showAxiosErrorMessage(error);
    }
}

function* NotificationSaga() {
    yield takeEvery('notification/updateDeviceTokenRequest', updateDeviceTokenRequestSaga);
}

export default NotificationSaga;