import {
  Button,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FC, useState } from "react";
import TimeItem from "./TimeItem";

interface Props {
  onTimeSelectionComplete: (selectedTime: string) => void;
}

const TimePicker: FC<Props> = ({ onTimeSelectionComplete }) => {
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [period, setPeriod] = useState<"AM" | "PM">("AM");

  const timeSelectionComplete = () => {
    let hourNum = hours;
    let minuteNum = minutes;

    if (period === "PM" && hourNum !== 12) {
      hourNum += 12;
    } else if (period === "AM" && hourNum === 12) {
      hourNum = 0;
    }

    const formattedTime = `${hourNum.toString().padStart(2, "0")}:${minuteNum
      .toString()
      .padStart(2, "0")}`;

    onTimeSelectionComplete(formattedTime);
  };

  return (
    <VStack gap={5}>
      <HStack gap={10} w="full" align="flex-start" justify="center">
        <VStack border="1px solid #555" p="10px" borderRadius={5}>
          <HStack gap={1}>
            <VStack gap={2}>
              {[1, 2, 3, 4, 5, 6].map((item) => (
                <TimeItem item={item} onClick={() => setHours(item)} />
              ))}
            </VStack>
            <VStack gap={2}>
              {[7, 8, 9, 10, 11, 12].map((item) => (
                <TimeItem item={item} onClick={() => setHours(item)} />
              ))}
            </VStack>
          </HStack>
          <RadioGroup
            bg="#000"
            p={2}
            borderRadius={5}
            onChange={(value) => setPeriod(value as "AM" | "PM")}
            value={period}
          >
            <Stack direction="row">
              <Radio value="AM">
                <Text color="#fff">AM</Text>
              </Radio>
              <Radio value="PM">
                <Text color="#fff">PM</Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </VStack>

        <HStack border="1px solid #555" p="10px" borderRadius={5}>
          <VStack>
            {[0, 5, 10, 15, 20, 25].map((item) => (
              <TimeItem item={item} onClick={() => setMinutes(item)} />
            ))}
          </VStack>
          <VStack>
            {[30, 35, 40, 45, 50, 55].map((item) => (
              <TimeItem item={item} onClick={() => setMinutes(item)} />
            ))}
          </VStack>
        </HStack>
      </HStack>
      <HStack>
        <Text
          bg="black"
          color="#fff"
          p={2}
          fontSize={50}
          fontWeight={800}
          borderRadius={8}
        >
          {hours < 10 ? `0${hours}` : hours}
        </Text>
        <Text
          bg="black"
          color="#fff"
          p={2}
          fontSize={50}
          fontWeight={800}
          borderRadius={8}
        >
          {minutes.toString().padStart(2, "0")}
        </Text>
        <Text
          bg="black"
          color="#fff"
          p={2}
          fontSize={50}
          fontWeight={800}
          borderRadius={8}
        >
          {period.toString().padStart(2, "0")}
        </Text>
      </HStack>
      <HStack>
        <Button
          bg="#000"
          onClick={timeSelectionComplete}
          alignSelf="flex-end"
          color="#fff"
          h="60px"
          w={200}
        >
          OK
        </Button>
      </HStack>
    </VStack>
  );
};

export default TimePicker;
