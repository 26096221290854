import { apiClient } from "api/axiosInstances";
import { ApiResponse } from "types/api";
import { UpdateDeviceTokenBodyParamsI } from "types/requests/notification";

export const updateDeviceTokenRequest = async (
    bodyParams: UpdateDeviceTokenBodyParamsI
): Promise<ApiResponse<any[]>> => {
    try {
        const response = await apiClient.post('/notifications', bodyParams);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};