import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRootState } from 'state/rootState';
import { UpdateDeviceTokenBodyParamsI } from 'types/requests/notification';

export interface INotificationState { }

export const NotificationSlice = createSlice({
    name: 'notification',
    initialState: {} as INotificationState,
    reducers: {
        updateDeviceTokenRequest: (state, action: PayloadAction<UpdateDeviceTokenBodyParamsI>) => { },
    }
});

export const NotificationSliceSelector = (state: IRootState) => state.notification;

export const {
    updateDeviceTokenRequest,
} = NotificationSlice.actions;

export default NotificationSlice.reducer;
