import { fork } from 'redux-saga/effects';
import shopSaga from './shop/shopSaga';
import propertySaga from './property/propertySaga';
import bookerSaga from './booker/bookerSaga';
import NotificationSaga from './notification/notificationSaga';

export default function* rootSaga() {
  yield fork(shopSaga);
  yield fork(propertySaga);
  yield fork(bookerSaga);
  yield fork(NotificationSaga);
}
