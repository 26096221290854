import {
  addTokenInterceptor,
  decodeResponseBody
} from 'api/interceptors/interceptors';
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

addTokenInterceptor(instance);
decodeResponseBody(instance);

export default instance;
