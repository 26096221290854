import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  IconButton,
  Tag,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineDown, AiOutlineLogout } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setWatchRange } from "state/shop/shopState";
import { WatchOption } from "types/requests/shop";
import { getWatchRangeFromWatchOption } from "utils/date";

function DateFilters() {
  const [selectedWatchOption, setSelectedWatchOption] = useState("Today");
  const { signOut } = useAuthenticator((context) => [context.user]);

  const dispatch = useDispatch();

  const onSelectWatchOption = (option: WatchOption) => {
    setSelectedWatchOption(option);
    const watchRange = getWatchRangeFromWatchOption(option);
    dispatch(setWatchRange(watchRange));
  };

  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<AiOutlineDown />}>
        {selectedWatchOption}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => onSelectWatchOption("Today")}>Today</MenuItem>
        <MenuItem onClick={() => onSelectWatchOption("Tomorrow")}>
          Tomorrow
        </MenuItem>
        <MenuItem onClick={() => onSelectWatchOption("This Week")}>
          This Week
        </MenuItem>
        <MenuItem onClick={() => onSelectWatchOption("Next Week")}>
          Next Week
        </MenuItem>
        <MenuItem onClick={() => onSelectWatchOption("This Month")}>
          This Month
        </MenuItem>
        <MenuItem onClick={() => onSelectWatchOption("Next Month")}>
          Next Month
        </MenuItem>
        <MenuItem onClick={() => onSelectWatchOption("This Year")}>
          This Year&nbsp;
          <Tag size="sm" bg="black" color="#fff">
            2024
          </Tag>
        </MenuItem>
        <MenuItem>
          <IconButton
            size={{
              base: "xs",
              md: "sm",
              lg: "md",
            }}
            colorScheme="red"
            aria-label="Logout button"
            icon={<AiOutlineLogout />}
            onClick={signOut}
          />
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default DateFilters;
