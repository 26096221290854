import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRootState } from 'state/rootState';
import { User } from 'types/user';

export interface IUserState {
    currantUser: User | undefined;
}

export const UserSlice = createSlice({
    name: 'user',
    initialState: {
        currantUser: undefined,
    } as IUserState,
    reducers: {
        setCurrantUser: (state, action: PayloadAction<User>) => {
            state.currantUser = action.payload;
        }
    }
});

export const UserSliceSelector = (state: IRootState) => state.user;

export const {
    setCurrantUser,
} = UserSlice.actions;

export default UserSlice.reducer;
