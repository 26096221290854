import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOwnerShopListRequest,
  ShopSliceSelector,
} from "state/shop/shopState";
import ShopList from "screens/owner/shop/ShopList/ShopList";
import { UserSliceSelector } from "state/user/userState";
import { IconButton, Spinner } from "@chakra-ui/react";
import { AiOutlineLogout } from "react-icons/ai";
import { useAuthenticator } from "@aws-amplify/ui-react";

const OwnerView: FC = () => {
  const shopSelector = useSelector(ShopSliceSelector);
  const userSliceSelector = useSelector(UserSliceSelector);
  const currantUser = userSliceSelector.currantUser;
  const shopList = shopSelector.shopList;
  const { signOut } = useAuthenticator((context) => [context.user]);

  const dispatch = useDispatch();

  useEffect(() => {
    shopList.length === 0 && getUserShops();
  }, []);

  const getUserShops = async () => {
    currantUser?.["cognito:username"] &&
      dispatch(
        getOwnerShopListRequest({ userId: currantUser?.["cognito:username"] })
      );
  };

  return shopSelector.loadings.shopList ? (
    <Spinner />
  ) : (
    <>
      <ShopList shops={shopList} />
      <IconButton
        size={{
          base: "xs",
          md: "sm",
          lg: "md",
        }}
        colorScheme="red"
        aria-label="Logout button"
        icon={<AiOutlineLogout />}
        onClick={signOut}
      />
    </>
  );
};

export default OwnerView;
