import { useAuthenticator } from "@aws-amplify/ui-react";
import { Center, HStack, IconButton, Text, VStack } from "@chakra-ui/react";
import QrCodeScanner from "components/QrCodeScanner/QrCodeScanner";
import { FC } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const BookerLanding: FC = () => {
  const navigate = useNavigate();
  const { signOut } = useAuthenticator((context) => [context.user]);

  const onScanComplete = (data: string) => {
    console.log("from parent ", data);
  };

  const onSelectShopCategory = (category: string) => {
    console.log("Selected Category: ", category);
    navigate("/shop-search");
  };

  return (
    <VStack h="full" w="full">
      <HStack>
        <QrCodeScanner onScanComplete={onScanComplete} />
      </HStack>
      <VStack h="full" w="full">
        <Center
          w="full"
          h="100px"
          bg="#610000"
          borderRadius={8}
          onClick={() => onSelectShopCategory("SALON")}
        >
          <Text color="#fff" fontSize={30}>
            Salon
          </Text>
        </Center>
        <Center
          w="full"
          h="100px"
          bg="#610000"
          borderRadius={8}
          onClick={() => onSelectShopCategory("GUEST")}
        >
          <Text color="#fff" fontSize={30}>
            Guest house
          </Text>
        </Center>
        <Center
          w="full"
          h="100px"
          bg="#610000"
          borderRadius={8}
          onClick={() => onSelectShopCategory("RESTAURANT")}
        >
          <Text color="#fff" fontSize={30}>
            Restaurants
          </Text>
        </Center>
        <IconButton
          size={{
            base: "xs",
            md: "sm",
            lg: "md",
          }}
          colorScheme="red"
          aria-label="Logout button"
          icon={<AiOutlineLogout />}
          onClick={signOut}
        />
      </VStack>
    </VStack>
  );
};

export default BookerLanding;
