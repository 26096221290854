import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastContainerProps, toast } from 'react-toastify';

interface ToastState {
  toastContainerProps: ToastContainerProps;
}

const initialState: ToastState = {
  toastContainerProps: {},
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    updateToastContainerProps: (
      state,
      action: PayloadAction<ToastContainerProps>
    ) => {
      state.toastContainerProps = action.payload;
    },
  },
});

// Export the actions and reducer
export const { updateToastContainerProps } = toastSlice.actions;
export const toastReducer = toastSlice.reducer;

// Custom toast functions
export const showSuccessToast = (message: string) => {
  toast.success(message);
};

export const showErrorToast = (message: string) => {
  toast.error(message);
};

export const showInfoToast = (message: string) => {
  toast.info(message);
};

export const showWarningToast = (message: string) => {
  toast.warning(message);
};

export const showAxiosErrorMessage = (error: any) => {
  if (error.response.data.message) {
    showErrorToast(error.response.data.message);
  } else {
    showErrorToast(error.message);
  }
};