import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRootState } from 'state/rootState';
import { OwnerShopListRequestQueryParamsI, PropertyBookingCount, ShopDashboardRequestBodyParamsI, WatchRange } from 'types/requests/shop';
import { OwnerShopListResponseI, ShopI } from 'types/responses/shop';
import { getWatchRangeFromWatchOption } from 'utils/date';

export interface IShopState {
    shopList: ShopI[] | [];
    selectedShop: ShopI | null;
    watchRange: WatchRange;
    propList: PropertyBookingCount[] | [];
    loadings: {
        shopList: boolean;
        shopDashboard: boolean;
    };
}

export const ShopSlice = createSlice({
    name: 'shop',
    initialState: {
        shopList: [],
        selectedShop: null,
        watchRange: getWatchRangeFromWatchOption('Today'),
        loadings: {
            shopList: false,
            shopDashboard: false
        }
    } as IShopState,
    reducers: {
        getOwnerShopListRequest: (state, action: PayloadAction<OwnerShopListRequestQueryParamsI>) => {
            state.loadings.shopList = true;
        },
        getOwnerShopListRequestSuccess: (state, action: PayloadAction<OwnerShopListResponseI>) => {
            state.shopList = action.payload.shops;
            state.loadings.shopList = false;
        },
        getOwnerShopListRequestFailure: (state) => {
            state.loadings.shopList = false;
        },
        getShopDashboardRequest: (state, action: PayloadAction<ShopDashboardRequestBodyParamsI>) => {
            state.propList = [];
            state.loadings.shopDashboard = true;
        },
        getShopDashboardRequestSuccess: (state, action: PayloadAction<PropertyBookingCount[]>) => {
            state.propList = action.payload
            state.loadings.shopDashboard = false;
        },
        getShopDashboardRequestFailure: (state) => {
            state.loadings.shopDashboard = false;
        },
        setWatchRange: (state, action: PayloadAction<WatchRange>) => {
            state.watchRange = action.payload;
        },
        setSelectedShop: (state, action: PayloadAction<ShopI>) => {
            state.selectedShop = action.payload;
        }
    }
});

export const ShopSliceSelector = (state: IRootState) => state.shop;

export const {
    getOwnerShopListRequest,
    getOwnerShopListRequestSuccess,
    getOwnerShopListRequestFailure,
    getShopDashboardRequest,
    getShopDashboardRequestSuccess,
    getShopDashboardRequestFailure,
    setWatchRange,
    setSelectedShop
} = ShopSlice.actions;

export default ShopSlice.reducer;
