// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBxrQjlPiwmHzK7ufEKKI2BmAZJVnr0Iq8",
  authDomain: "property-booking-600ca.firebaseapp.com",
  projectId: "property-booking-600ca",
  storageBucket: "property-booking-600ca.appspot.com",
  messagingSenderId: "282168651127",
  appId: "1:282168651127:web:f8cfeaacf682c79afe0572",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestFirebaseNotificationPermission = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: "BPcxw5POOHq0JNpVRo_WgaFmi7LMkb565SALCx0UBZ5-4VNxoRIwaaHOOoWZYCmvocaf558Xxnc8rtZXNprClx8",
    });
    if (token) {
      return token;
    } else {
      console.warn("No Firebase token received");
    }
  } catch (error) {
    console.error("Error requesting permission for notifications:", error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
