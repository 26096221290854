import { FC } from "react";
import { Button, Grid, GridItem } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedShop } from "state/shop/shopState";
import { ShopI } from "types/responses/shop";

interface Props {
  shops: ShopI[];
}

const ShopList: FC<Props> = ({ shops }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToShop = (shop: ShopI) => {
    dispatch(setSelectedShop(shop));
    navigate("/shop");
  };

  return (
    <Grid
      templateColumns={{
        base: "repeat(1, 1fr)",
        md: "repeat(3, 1fr)",
        lg: "repeat(4, 1fr)",
      }}
      rowGap={{
        base: 1,
        md: 2,
        lg: 5,
      }}
      columnGap={8}
      p={1}
    >
      {shops.length > 0 &&
        shops.map((shop) => (
          <GridItem key={shop.shopId}>
            <Button
              colorScheme="orange"
              color="white"
              size={{
                base: "lg",
              }}
              w="100%"
              onClick={() => goToShop(shop)}
            >
              {shop.shopId}
            </Button>
          </GridItem>
        ))}
    </Grid>
  );
};

export default ShopList;
